import makeStyles from "@material-ui/core/styles/makeStyles";
import WelcomeBanner from "./WelcomeBanner/WelcomeBanner";
import Navbar from "../Navbar/Navbar";
import OurServiceSection from "./OurServiceSection/OurServiceSection";
import SciencesSection from "./SciencesSection/SciencesSection";
import BannerSection from "./BannerSection/BannerSection";
import OurCasesSection from "./OurCasesSection/OurCasesSection";
import bgImage from "../../../assets/bio/background/molecular-bg.png"
import React, {useState} from "react";
import { Background, Parallax } from 'react-parallax';
import ScienceModal from "./SciencesSection/ScienceModal/ScienceModal";
import DescriptionCards from "./SciencesSection/ScienceCard/DescriptionCards";


const useStyles = makeStyles({
    HomePage: {
        // overflow: "hidden",
        // boxSizing: "border-box",
        alignItems: "center",
        // justifyContent: "center",
        display: "flex",
        flexDirection: "column",
    },
    background: {
        width: "100%",
        backgroundSize: "auto",
        backgroundRepeat: "repeat-y",
        zIndex: 0,
    },
});

const HomePage = props => {
    const classes = useStyles();
    const [modalScience, setModalScience] = useState({isOpen: false});

    const getModalLauncher = science => () => {
        console.log("launching modal");
        setModalScience({science: science, isOpen: true});
    };

    const closeModal = () => {
        console.log("closing modal");
        setModalScience({isOpen: false});
    };

    return (
        <div className={classes.HomePage}>
            <Navbar/>
            <WelcomeBanner/>
            <DescriptionCards getModalLauncher={getModalLauncher}/>
            <SciencesSection getModalLauncher={getModalLauncher}/>
            {/*<Parallax bgImageSizes={classes.background} bgStyle={classes.background} bgImage={bgImage} strength={1000} blur={{min: 0, max: 0}}>*/}
            {/*    */}
            {/*    /!*<OurCasesSection/>*!/*/}
            {/*</Parallax>*/}
            <BannerSection/>
            <ScienceModal science={modalScience.science} open={modalScience.isOpen} handleClose={closeModal}/>
        </div>
    )
};

export default HomePage;