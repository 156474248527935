import makeStyles from  "@material-ui/core/styles/makeStyles";
import {useContext} from "react";
import {EnvironmentContext} from "../../App/App";

const useStyles = makeStyles({
    SectionHeader: props => ({
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: props.size || props.fontSize || props.innerWidth > 600 ? 48 : 42,
        lineHeight: "167.19%",
        color: props.color || "#1E217C",
        marginRight: 15,
        marginLeft: 15,
        marginTop: props.innerWidth > 600 ? 0 : -50
        // background: "-webkit-linear-gradient(#FF7A00, #FFA800)",
        // "-webkit-background-clip": "text",
        // "-webkit-text-fill-color": "transparent",
    }),
})

const SectionTitle = props => {
    const {innerWidth} = useContext(EnvironmentContext);
    const classes = useStyles({...props, innerWidth});
    return (
        <h3 className={classes.SectionHeader}>
            {props.children}
        </h3>
    )
}

export default SectionTitle;