import makeStyles from  "@material-ui/core/styles/makeStyles";
import SectionTitle from "../../TextComponents/SectionTitle";
import { portfolioCases } from "./portfolioCases";
import CasePreviewPage from "./CasePreviewPage";
import PageSelectGallery from "../../../PageSelectGallery/PageSelectGallery";

const useStyles = makeStyles({
    OurCasesSection: props => ({
        width: "100vw",
        height: "90vh",
        position: "relative",
        paddingBottom: "3vh",
    }),
    titleRow: {
        height: "10%",
        margin: 0,
        boxSizing: "border-box",
    },
    contentSection: props => ({
        display: "flex",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "90%",
        margin: 0,
        boxSizing: "border-box",
    }),
})


const OurCasesSection = props => {

    const classes = useStyles(props);

    return (
        <section className={classes.OurCasesSection}>
            <div className={classes.titleRow}>
                <SectionTitle>Our Cases</SectionTitle>
            </div>

            <PageSelectGallery pageData={portfolioCases} pageComponent={CasePreviewPage} pageIndicator/>


        </section>
    )
}

export default OurCasesSection;