import makeStyles from  "@material-ui/core/styles/makeStyles";
import {IconButton} from "@material-ui/core";
import {ArrowBackIos} from "@material-ui/icons";

const useStyles = makeStyles({
    PrevIconButton: props => ({
        // shared between next and prev buttons
        position: "absolute",
        background: "white",
        borderRadius: props.size,
        width: props.size,
        height: props.size,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        userSelect: "none",
        cursor: "pointer",
        fontWeight: "bold",
        fontSize: props.size / 2,
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
        top: "50%",
        zIndex: 3,


        left: "3%",
    }),
    icon: props => ({
        position: "relative",
        left: props.size / 12,
    })
})

const PrevIconButton = props => {
    const {size = 60} = props;
    const classes = useStyles({...props, size});
    return (
        <IconButton className={classes.PrevIconButton} onClick={props.onClick}>
            <ArrowBackIos className={classes.icon} fontSize={"inherit"}/>
        </IconButton>
    )
}

export default PrevIconButton;