import makeStyles from "@material-ui/core/styles/makeStyles";
import ContactUsButton from "../../SoftwareDevelopment/HomePage/Buttons/ContactUsButton";

const useStyles = makeStyles({
    ContactPage: {
        // height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
    },
    ContentContainer: {
        width: "min(100vw, 800px)",
    },
    Header: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 42,
        color: "#1E217C",
        marginTop: 100,
        paddingLeft: 50,
        paddingRight: 50,
        marginBottom: 50,
    },
    SubHeader: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 36,
        color: "#1E217C",
        paddingLeft: 50,
        paddingRight: 50,
        marginBottom: 100,
    }
});

const CareersPage = props => {
    const classes = useStyles();
    return (
        <div className={classes.ContactPage}>
            <div className={classes.ContentContainer}>
                <br/>
                <br/>
                <br/>
                <h1 className={classes.Header}>Page coming Soon</h1>
                <h1 className={classes.SubHeader}>In the meantime, we're actively hiring. Tell us about your skills.</h1>
                <ContactUsButton children="Ask about careers" size={325}/>
            </div>
        </div>
    )
};

export default CareersPage;