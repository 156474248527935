import makeStyles from "@material-ui/core/styles/makeStyles";
import {Button} from "@material-ui/core";
import {minWinDimPercent, scaleCssSize} from "../../../../BigBoyFunctions";
import {useContext} from "react";
import {EnvironmentContext} from "../../../App/App";

const useStyles = makeStyles({
    LearnMoreButton: props => {
        const width = props.width || props.size || props.percent(25);
        const height =  props.height || props.size / 5 || props.percent(6);

        return ({
            background: props.background || "transparent",
            color: props.color || "white",
            width,
            height,
            borderRadius: scaleCssSize(height, 0.5),
            border: props.border || "2px solid white",
            margin: props.margin || 20,

            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: scaleCssSize(height, 0.3),
            lineHeight: "86%",

            ...(props.style),
            // ...props
        })
    }
})


const LearnMoreButton = props => {
    const {scaleMinWinDim} = useContext(EnvironmentContext);

    const classes = useStyles({...props, percent: scaleMinWinDim});

    return (
        <Button
            className={classes.LearnMoreButton}
            {...props}
        >
            {props.children || "Learn more"}
        </Button>
    )
}


export default LearnMoreButton;