import makeStyles from  "@material-ui/core/styles/makeStyles";
import ContactUsButton from "../../../SoftwareDevelopment/HomePage/Buttons/ContactUsButton";
import imgSupport from "../../../../assets/bio/support/support-dark.jpeg";
import {useContext} from "react";
import {EnvironmentContext} from "../../../App/App";


const useStyles = makeStyles({
    BannerSection: props => ({
        position: "relative",
        height: props.height,
        width: "100%",
        color: "white",
        clip: "rect(0, 0, 100, 100)",
        backgroundImage: `url(${imgSupport})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",

        // border: "2px solid orange"
    }),
    background: {
        // height: "100%",
        // height: "100%",
        // position: "absolute",
        // transform: "scaleX(-1)",
        backgroundImage: `url(${imgSupport})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        // zIndex: 50
    },
    content: props => ({
        height: props.height,
        width: "100%",
        position: "absolute",
        display: "flex",
        alignItems: "center",
        // border: "2px solid red",
    }),
    leftSide: {
        height: "100%",
        width: "10%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    rightSide: {
        // height: "60%",
        width: "60%",
        padding: "20px 0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        textAlign: "left",
        // border: "2px solid blue",
    },
    headline: props => ({
        padding: 0,
        margin: 0,
        fontFamily: "Montserrat, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: props.innerWidth > 600 ? 38 : 28,
        lineHeight: "80%"
    }),
    subtext: {
        marginBottom: 40,
        fontFamily: "Montserrat, sans-serif",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "20px",
        lineHeight: "128.19%"
    }
})

const BannerSection = props => {
    const {height = 300} = props;
    const {innerWidth} = useContext(EnvironmentContext);
    const classes = useStyles({...props, innerWidth, height});
    return (
        <div className={classes.BannerSection}>
            <div className={classes.background}>
                {/*<img width={"100%"} src={imgSupport} alt={""}/>*/}
            </div>
            <div className={classes.content}>
                <div className={classes.leftSide}>
                </div>
                <div className={classes.rightSide}>
                    <h2 className={classes.headline}>Just need some advice?</h2>
                    <p className={classes.subtext}>If we’re not the best solution, we’ll tell you who is</p>
                    <ContactUsButton size={height / 1.6} background={"linear-gradient(92.72deg, #FF7A00 0.92%, #FFA800 88.24%)"}/>
                </div>
            </div>


        </div>
    )
}

export default BannerSection;