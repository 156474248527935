import makeStyles from "@material-ui/core/styles/makeStyles";
import {cssSizeToPX} from "../../../BigBoyFunctions";

const useStyles = makeStyles({
    NucleateTitle: props => ({
        fontFamily: "Gotham Pro",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: cssSizeToPX(props.size || 36),
        lineHeight: "167.19%",
        color: "white",
    })
})

const NucleateTitle = props => {
    const classes = useStyles(props)

    return (
        <h1 className={classes.NucleateTitle}>Nucleate</h1>
    )
}


export default NucleateTitle;