import makeStyles from  "@material-ui/core/styles/makeStyles";
import PropTypes from "prop-types";
import {motion, useMotionValue, useTransform} from "framer-motion";
import _ from "lodash"
import {useEffect, useState} from "react";
import uuid from "react-uuid"

const useStyles = makeStyles({
    PageIndicator: props => ({
        display: "flex",
        justifyContent: "center",
        border: "1px solid red",
    }),
    PageDot: props => ({
        borderRadius: "50%",
        // margin: "50%",
        marginLeft: props.size / 4,
        marginRight: props.size / 4,
        height: props.size,
        width: props.size,
        background: "rgba(238, 238, 238, 1)",
    }),
    indicator: {

    }
})

const PageIndicator = props => {

    const {size = 50} = props;
    const classes = useStyles({...props, size});

    const indexArray = _.range(props.numPages)

    const currentIndex = useMotionValue(props.current);
    const x = useTransform(currentIndex, [0, props.numPages - 1], [0, props.numPages * (size * 1.5)])

    useEffect(() => {
        currentIndex.set(props.current)

        // console.log("indicatorX changed: ", indicatorX)
    }, [props.current])
    
    useEffect(() => {
        console.log("currentIndex changed: ", currentIndex, " indicatorX: ", x);
    }, [currentIndex])

    return (
        <motion.div
            className={classes.PageIndicator}
        >
            {indexArray.map((index) => (
                <div key={uuid()} className={classes.PageDot}/>
            ))}

            <motion.div
                // className={classes.indicator}
                style={{
                    borderRadius: size / 2,
                    border: "2px solid purple",
                    height: size,
                    width: size,
                    position: "absolute",
                }}
                animate={{
                    // height: size,
                    // width: size,
                    // x: indicatorX,
                    // scale: indicatorX.getVelocity(),
                    // x: currentIndex.get() * (size * 1.5),
                    // x,
                    // scale: currentIndex.getVelocity(),
                    x: currentIndex.get,
                    // background: "orange"
                }}
            >

            </motion.div>

        </motion.div>
    )
}

PageIndicator.propTypes = {
    numPages: PropTypes.number.isRequired,
    current: PropTypes.number.isRequired,
    onChange: PropTypes.func
}

export default PageIndicator;