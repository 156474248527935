import logoPlaceholder from "./logoPlaceholder.svg"
import makeStyles from  "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
    Logo: props => ({
        ...(props.style)
    }),
})

const Logo = props => {
    const classes = useStyles(props);

    return (
        <img className={classes.Logo} src={logoPlaceholder} alt={"Nucleate Logo"}/>
    )
}

export default Logo;
