import makeStyles from  "@material-ui/core/styles/makeStyles";
import {useContext} from "react";
import {EnvironmentContext} from "../../../../App/App";
import uuid from "react-uuid";
import React from "react";
import ScienceCardDescriptor from "./ScienceCardDescriptor";
import ScienceCardVid from "./ScienceCardVid";
import Modal from '@material-ui/core/Modal';


const useStyles = makeStyles({
    ScienceCard: props => ({
        display: "flex",
        width: "min(600px, 100vw)",
        justifyContent: "center",
        margin: props.innerWidth > 600 ? 25 : 10,
        marginRight: props.innerWidth > 1200 ? 25 : 0,
        marginLeft: props.innerWidth > 1200 ? 25 : 0,
        padding: 0,
        borderRadius: 10,
        backgroundColor: "rgba(255, 255, 255, 0.75)",
        boxShadow: "0 0 16px 3px rgba(0,0,0,0.10)",
        transition: "ease 0.4s",

        "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 1)",
            boxShadow: "0 0 64px 10px rgba(0,0,0,0.10)",
            transition: "ease 0.2s",
            transform: "scale(1.02)",
        },
        // border: "2px solid blue",


    })
});

const ScienceCard = props => {

    const {scaleMinWinDim, innerWidth} = useContext(EnvironmentContext);

    const classes = useStyles({percent: scaleMinWinDim, innerWidth});

    return (
        <div onClick={props.onClick} className={classes.ScienceCard}>
            <ScienceCardVid key={uuid()} {...props}/>
        </div>
    )
}

export default //ScienceCard
React.memo(
    ScienceCard,
    (prev, next) => (
        true
        // prev.onClick === next.onClick && prev.title === next.title && prev.
    )

);