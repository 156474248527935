import makeStyles from "@material-ui/core/styles/makeStyles";
import {Button} from "@material-ui/core";
import {scaleCssSize, omit} from "../../../BigBoyFunctions";
import {useContext} from "react";
import {EnvironmentContext} from "../../App/App";
import React from 'react';


const useStyles = makeStyles({
    SendButton: props => {
        const width = props.width || props.size || props.percent(25);
        const height =  props.height || props.size / 5 || props.percent(6);

        return ({
            background: props.background || "#FF7E01",
            color: props.color || "white",
            width,
            height,
            borderRadius: scaleCssSize(height, 0.5),
            border: props.border || "none",
            // margin: props.margin || 20,

            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: scaleCssSize(height, 0.35),
            lineHeight: "86%",

            ...(props.style),
        })
    }
});


const SendButton = props => {
    const {scaleMinWinDim} = useContext(EnvironmentContext);

    const classes = useStyles({...props, percent: scaleMinWinDim});

    return (
        <Button
            className={classes.SendButton}
            {...omit(props, ["size", "height", "width"])}
            onClick={props.onClick}
        >
            {props.children || "Send"}
        </Button>
    )
};


export default SendButton;