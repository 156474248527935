import makeStyles from "@material-ui/core/styles/makeStyles";
import WelcomeBanner from "./WelcomeBanner/WelcomeBanner";
import Navbar from "../Navbar/Navbar";
import OurServiceSection from "./OurServiceSection/OurServiceSection";
import BannerSection from "./BannerSection/BannerSection";
import OurCasesSection from "./OurCasesSection/OurCasesSection";

const useStyles = makeStyles({
    HomePage: {
        overflow: "hidden",
    }
})

const HomePage = props => {
    const classes = useStyles();
    return (
        <div className={classes.HomePage}>
            <Navbar/>
            <WelcomeBanner/>
            <OurServiceSection/>
            <BannerSection/>
            <OurCasesSection/>
        </div>
    )
}

export default HomePage;