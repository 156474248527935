import makeStyles from  "@material-ui/core/styles/makeStyles";
import React, {useContext, useEffect, useRef, useState} from "react";
import {EnvironmentContext} from "../../../../App/App";
// import ReactPlayer from "react-player";
import {motion} from "framer-motion";
import {useInView} from "react-intersection-observer";

const useStyles = makeStyles({
    VidBox: props => ({
        position: "relative",
        display: "flex",
        borderRadius: 10,
        margin: 0,
        padding: 0,
        background: "transparent",
        // width: "40vw",
        // border: "2px solid blue",
        overflow: "hidden",
        height: props.height,
        width: props.width
    }),
    overlay: props => ({
        position: "absolute",
        height: "100%",
        width: "100%",
        color: "white",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        fontFamily: "Montserrat, sans-serif",
        fontSize: props.innerWidth > 600 ? 52 : 36,
        "& h5": {
            margin: 20
        }
        // background: "rgba(0,0,0,0.3)"
    })
});

const ScienceCardVid = props => {
    // const [playing, setPlaying] = useState(false);s
    const [dims, setDims] = useState([0, 0])
    const [containerRef, inView] = useInView();
    const videoRef = useRef();

    useEffect(() => {
        setDims([videoRef.clientWidth, videoRef.clientHeight])
    }, []);

    useEffect(() => {
        if (inView) {
            videoRef.current.play();
        }
        else {
            videoRef.current.pause();
        }
    }, [inView]);

    const {innerWidth, scaleMinWinDim} = useContext(EnvironmentContext);

    const classes = useStyles({percent: scaleMinWinDim, innerWidth, borderRadius: 10, width: dims[0], height: dims[1]});

    return (
        <motion.div ref={containerRef} className={classes.VidBox}>
            <video className={classes.video} ref={videoRef} src={props.vid} width={"100%"} autoPlay={false} loop={false} muted />
            <div className={classes.overlay}>
                <h5>{props.title}</h5>
            </div>
        </motion.div>
    )
};

export default ScienceCardVid;