import makeStyles from "@material-ui/core/styles/makeStyles";
import {Link, Route, Switch, useRouteMatch} from "react-router-dom";
import HomePage from "./HomePage/HomePage";
import ContactPage from "./ContactPage/ContactPage";

const useStyles = makeStyles({

})

const SoftwareDevelopment = props => {
    const {path, url} = useRouteMatch();

    
    return (
        <div>
            <Switch>
                <Route exact path={path} component={HomePage}/>
                <Route exact path={`${url}/contact`} component={ContactPage}/>
            </Switch>
        </div>
    )
}


export default SoftwareDevelopment;