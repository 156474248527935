import {motion, useTransform, useViewportScroll} from "framer-motion";
import makeStyles from "@material-ui/core/styles/makeStyles";
import NucleateTitle from "../Branding/NucleateTitle";
import Logo from "../Branding/Logo";
import ContactUsButton from "../HomePage/Buttons/ContactUsButton";
import {Link} from "react-router-dom";
// import {useContext, useEffect} from "react";
// import {EnvironmentContext} from "../../App/App";

const sectionStyleBase = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // border: "2px solid orange"
}


const useStyles = makeStyles({
    Navbar: props => ({
        width: "100vw",
        height: 70,
        position: "fixed",
        zIndex: 1000,
        boxSizing: "border-box",
        padding: 0,
    }),
    background: {
        background: `linear-gradient(225deg, rgb(98, 49, 239) 0%, rgb(153, 63, 248) 85%)`,
        width: "100%",
        height: "100%",
        margin: 0,
        position: "absolute"
    },
    content: {
        position: "absolute",
        padding: "0px 30px",
        width: "100vw",
        height: "100%",
        margin: 0,
        display: "flex",
        boxSizing: "border-box",
    },
    titleSection: {
        ...sectionStyleBase,
        width: 250,
    },
    middleSection: {
        ...sectionStyleBase,
        width: "80%",
    },
    rightSection: {
        ...sectionStyleBase,
        width: 250,
    },
    NavLink: {
        textDecoration: "none",
        margin: "0 50px"
    },
    navLinkText: {
        fontFamily: "Montserrat, sans-serif",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 18,
        lineHeight: "167.19%",
        color: "white",
    }
})

const NavLink = props => {
    const classes = useStyles();

    return (
        <Link className={classes.NavLink} to={props.to}>
            <h3 className={classes.navLinkText}>
                {props.children}
            </h3>
        </Link>
    )
}

const Navbar = props => {
    const {scrollY} = useViewportScroll();
    const opacity = useTransform(scrollY, [0, 200, 500], [0, 1, 1])

    const classes = useStyles({...props, opacity});

    return (
        <motion.div className={classes.Navbar}>
            <motion.div className={classes.background} animate={{opacity}}/>
            <div className={classes.content}>
                <div className={classes.titleSection}>
                    <Logo style={{marginRight: 10}}/>
                    <NucleateTitle/>
                </div>
                <div className={classes.middleSection}>
                    <NavLink to={"/software"}>About</NavLink>
                    <NavLink to={"/software"}>Process</NavLink>
                </div>
                <div className={classes.rightSection}>
                    <ContactUsButton
                        style={{
                            background: "linear-gradient(92.72deg, #FF7A00 0.92%, #FFA800 88.24%)",
                        }}
                        width={150}
                        height={40}
                    />
                </div>
            </div>

        </motion.div>
    )
}


export default Navbar