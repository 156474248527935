import browserGearIcon from "../../../../assets/icons/SoftwareDevelopment/serviceIcons/browserGear.png";
import chartIcon from "../../../../assets/icons/SoftwareDevelopment/serviceIcons/analyticsChart.png";
import designIcon from "../../../../assets/icons/SoftwareDevelopment/serviceIcons/design.png";
import networkGlobeIcon from "../../../../assets/icons/SoftwareDevelopment/serviceIcons/networkGlobe.png";
import cloudNetworkIcon from "../../../../assets/icons/SoftwareDevelopment/serviceIcons/cloudNetwork.png";
import apiIcon from "../../../../assets/icons/SoftwareDevelopment/serviceIcons/apiCloudGear.png";

const services = [
    {
        title: "Mobile & Web Apps",
        body: "Irure sint culpa consectetur nostrud nulla. Sunt aliqua minim irure eu.\n",
        icon: browserGearIcon,
    },
    {
        title: "Dashboard & Analytics",
        body: "Irure sint culpa consectetur nostrud nulla. Sunt aliqua minim irure eu.\n",
        icon: chartIcon,
    },
    {
        title: "UX/UI Design",
        body: "Irure sint culpa consectetur nostrud nulla. Sunt aliqua minim irure eu.\n",
        icon: designIcon,
    },
    {
        title: "Automation & Web Crawling",
        body: "Irure sint culpa consectetur nostrud nulla. Sunt aliqua minim irure eu.\n",
        icon: networkGlobeIcon,
    },
    {
        title: "Cloud Infrastructure",
        body: "Irure sint culpa consectetur nostrud nulla. Sunt aliqua minim irure eu.\n",
        icon: cloudNetworkIcon,
    },
    {
        title: "Databases & APIs",
        body: "Irure sint culpa consectetur nostrud nulla. Sunt aliqua minim irure eu.\n",
        icon: apiIcon,
    },
]

export default services