export const portfolioCases = {
    OnRecord: {
        title: "OnRecord",
        summary: "Irure sint culpa consectetur nostrud nulla. Sunt aliqua minim irure e Irure sint culpa consectetur " +
            "nostrud nulla. Sunt aliqua minim irure e Irure sint culpa consectetur nostrud nulla. Sunt aliqua minim " +
            "irure e",
        images: [
            "https://d33wubrfki0l68.cloudfront.net/dd23708ebc4053551bb33e18b7174e73b6e1710b/dea24/static/images/wallpapers/shared-colors@2x.png"
        ],

    },
    TestCase2: {
        title: "Test Case 2",
        summary: "Irure sint culpa consectetur nostrud nulla. Sunt aliqua minim irure e Irure sint culpa consectetur " +
            "nostrud nulla. Sunt aliqua minim irure e Irure sint culpa consectetur nostrud nulla. Sunt aliqua minim " +
            "irure e",
        images: [
            "https://d33wubrfki0l68.cloudfront.net/49de349d12db851952c5556f3c637ca772745316/cfc56/static/images/wallpapers/bridge-02@2x.png"
        ],
    },
    TestCase3: {
        title: "Test Case 3",
        summary: "Irure sint culpa consectetur nostrud nulla. Sunt aliqua minim irure e Irure sint culpa consectetur " +
            "nostrud nulla. Sunt aliqua minim irure e Irure sint culpa consectetur nostrud nulla. Sunt aliqua minim " +
            "irure e",
        images: [
            "https://d33wubrfki0l68.cloudfront.net/594de66469079c21fc54c14db0591305a1198dd6/3f4b1/static/images/wallpapers/bridge-01@2x.png"
        ],
    },
}