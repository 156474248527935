import makeStyles from "@material-ui/core/styles/makeStyles";
import BannerBackdrop from "./BannerBackdrop/BannerBackdrop";
import welcomeArt from "./welcomeArt.png"
import {Button} from "@material-ui/core";
import LearnMoreButton from "../Buttons/LearnMoreButton";
import ContactUsButton from "../Buttons/ContactUsButton";
import {useContext} from "react";
import {EnvironmentContext} from "../../../App/App";

const useStyles = makeStyles({
    WelcomeBanner: {
        height: "100vh",
        width: "100vw",
    },
    topSpacer: props => ({
        height: props.percent(10),
        width: "70%"
    }),
    bannerContent: {
        // background: "black"
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        zIndex: 10
    },
    welcomeArt: props => ({
        zIndex: 5,
        background: "transparent",
        height: props.percent(50),
        // width: "50vw"
    }),
    header: props => ({
        fontFamily: "Montserrat, sans-serif",
        fontStyle: "normal",
        fontWeight: 200,
        fontSize: props.percent(4),
        lineHeight: "140%",
        textAlign: "center",
        color: "#FFFFFF",
        marginBottom: 0,
    }),
    subHeader: props => ({
        fontFamily: "Montserrat, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: props.percent(4),
        lineHeight: "140%",
        textAlign: "center",
        color: "#FFFFFF",
        marginTop: 0,
        marginBottom: props.percent(5),
    }),
    buttonRow: props => ({
        marginBottom: props.percent(5),
    })
})

const WelcomeBanner = props => {
    const {scaleMinWinDim} = useContext(EnvironmentContext);

    const classes = useStyles({percent: scaleMinWinDim});

    return (
        <section className={classes.WelcomeBanner}>
            {/*<div>*/}

            {/*</div>*/}
            <div className={classes.bannerContent}>
                <div className={classes.topSpacer}/>

                <h1 className={classes.header}>
                    Empower your business
                </h1>
                <h1 className={classes.subHeader}>
                    with tailored apps and technology
                </h1>

                <div className={classes.buttonRow}>
                    <LearnMoreButton style={{margin: 40}}/>
                    <ContactUsButton style={{margin: 40}}/>
                </div>

                <img className={classes.welcomeArt} src={welcomeArt} alt={"Welcome"}/>
            </div>
            <BannerBackdrop height={"75%"}/>
        </section>
    )
}


export default WelcomeBanner;