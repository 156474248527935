import makeStyles from  "@material-ui/core/styles/makeStyles";
import BannerBackdrop from "../WelcomeBanner/BannerBackdrop/BannerBackdrop";
import ContactUsButton from "../Buttons/ContactUsButton";
import phoneClipArt from "../../../../assets/software/clipArt/phoneAppClipArt.png";

const useStyles = makeStyles({
    BannerSection: props => ({
        position: "relative",
        height: props.height,
        width: "100%",
        color: "white",
        // border: "2px solid orange"
    }),
    background: {
        height: "100%",
        width: "100%",
        position: "absolute",
    },
    content: props => ({
        height: props.height,
        width: "100%",
        position: "absolute",
        display: "flex",
        alignItems: "center",
        // border: "2px solid red",
    }),
    leftSide: {
        height: "100%",
        width: "40%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    rightSide: {
        // height: "60%",
        width: "60%",
        padding: "20px 0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        textAlign: "left",
        // border: "2px solid blue",
    },
    headline: {
        padding: 0,
        margin: 0,
        fontFamily: "Gotham Pro",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "38px",
        lineHeight: "80%"
    },
    subtext: {
        marginBottom: 40,
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "20px",
        lineHeight: "128.19%"
    }
})

const BannerSection = props => {
    const {height = 300} = props;
    const classes = useStyles({props, height});
    return (
        <div className={classes.BannerSection}>
            <div className={classes.background}>
                <BannerBackdrop y={-400}/>
            </div>
            <div className={classes.content}>
                <div className={classes.leftSide}>
                    <img height={"100%"} src={phoneClipArt} alt={"Phone app clip art"}/>
                </div>
                <div className={classes.rightSide}>
                    <h2 className={classes.headline}>Just need some advice?</h2>
                    <p className={classes.subtext}>If we’re not the best solution, we’ll tell you who is</p>
                    <ContactUsButton size={height / 1.6}/>
                </div>
            </div>


        </div>
    )
}

export default BannerSection;