import makeStyles from  "@material-ui/core/styles/makeStyles";
import {useContext} from "react";
import {EnvironmentContext} from "../../../App/App";

const useStyles = makeStyles({
    ServiceItemCard: props => ({
        // width: "372px",
        // height: "457px",
        width: props.percent(20),
        height: props.percent(25),
        left: "276px",
        top: "1528px",
        background: "#FFFFFF",
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.09)",
        borderRadius: "12px",
        padding: props.percent(5),
        margin: props.percent(5),
        textAlign: "left",

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    }),
    icon: props => ({
        width: props.percent(7),
        height: props.percent(7),
        marginBottom: 10
    }),
    title: props => ({
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        // fontSize: "24px",
        fontSize: props.percent(1.6),
        lineHeight: "167.19%",
        // marginTop: 10,
        marginBottom: 10
    }),
    body: props => ({
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        // fontSize: "18px",
        fontSize: props.percent(1.3),
        lineHeight: "167.19%"
    })
})

const ServiceItemCard = props => {

    const {scaleMinWinDim} = useContext(EnvironmentContext);

    const classes = useStyles({percent: scaleMinWinDim});

    return (
        <div className={classes.ServiceItemCard}>

            <img
                className={classes.icon}
                src={props.icon}
                alt={props.title}
            />

            <h5
                className={classes.title}>
                {props.title}
            </h5>
            <p
                className={classes.body}>
                {props.body}
            </p>

        </div>
    )
}

export default ServiceItemCard;