import makeStyles from  "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
    CasePreviewPage: props => ({
        width: "80%",
        height: "90%",
        display: "flex",
        // alignItems: "center",
        justifyContent: "space-between",
    }),
    leftSide: {
        width: "55%",
        // height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        boxSizing: "border-box",
    },
    bigImage: {
        width: "100%"
    },
    rightSide: {
        width: "45%",
        textAlign: "left",
        display: "flex",
        // alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        boxSizing: "border-box",
        padding: "5%"
    },
    numberIndicator: {
        color: "rgba(255, 167, 1, 1)",
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "48px",
        lineHeight: "86%",
    },
    title: {

    },
    summary: {

    }
})

const formatIndex = (index) => {
    return (index < 9) ? `0${index + 1}` : `${index + 1}`;

}

const CasePreviewPage = props => {
    const classes = useStyles(props);
    return (
        <div className={classes.CasePreviewPage}>
            <div className={classes.leftSide}>
                {(props.images.length > 1) ? (
                    <div/>
                ) : (
                    <img
                        className={classes.bigImage}
                        src={props.images[0]}
                    />
                )}
            </div>
            <div className={classes.rightSide}>
                <h1 className={classes.numberIndicator}>{formatIndex(props.index)}</h1>
                <h3 className={classes.title}>{props.title}</h3>
                <p className={classes.summary}>{props.summary}</p>
            </div>
        </div>
    )
}

export default CasePreviewPage;