import makeStyles from  "@material-ui/core/styles/makeStyles";
import SectionTitle from "../../TextComponents/SectionTitle";
import Grid from "./Grid";
import services from "./services";
import ServiceItemCard from "./ServiceItemCard";
import uuid from "react-uuid"


const useStyles = makeStyles({
    OurServiceSection: props => ({
        marginTop: "5vh",
        paddingBottom: "3vh",
        color: "#695CC1",
    }),
})

const OurServiceSection = props => {
    const classes = useStyles(props);
    return (
        <section className={classes.OurServiceSection}>
            <SectionTitle>Our Service</SectionTitle>
            <Grid style={{width: "80vw", margin: "auto"}}>
                {services.map(service => (
                    <ServiceItemCard key={uuid()} {...service}/>
                ))}
            </Grid>
        </section>
    )
}

export default OurServiceSection;