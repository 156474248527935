import makeStyles from "@material-ui/core/styles/makeStyles";
import WelcomeBanner from "./WelcomeBanner/WelcomeBanner";
import Navbar from "../Navbar/Navbar";

const useStyles = makeStyles({
    HomePage: {
        width: "100vw",
        boxSizing: "border-box",
        background: "red"
    }
})


const HomePage = props => {
    const classes = useStyles();
    return (
        <div className={classes.HomePage}>
            {/*<Navbar/>*/}
            <WelcomeBanner/>
            {/*<WelcomeBanner/>*/}
        </div>
    )
}


export default HomePage;