import makeStyles from "@material-ui/core/styles/makeStyles";
import {Link, Route, Switch, useRouteMatch} from "react-router-dom";
import HomePage from "./HomePage/HomePage";
import ContactPage from "./ContactPage/ContactPage";
import CareersPage from "./CareersPage/CareersPage";


const useStyles = makeStyles({
    Bio: {
        boxSizing: "border-box",
        overflow: "hidden"
    }
})

const Bio = props => {
    const {path, url} = useRouteMatch();
    const classes = useStyles();
    
    return (
        <div className={classes.Bio}>
            <Switch>
                <Route exact path={path} component={HomePage}/>
                <Route exact path={`/contact`} component={ContactPage}/>
                <Route exact path={`/careers`} component={CareersPage}/>

            </Switch>
        </div>
    )
}


export default Bio;