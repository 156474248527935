import makeStyles from  "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
    SectionHeader: props => ({
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: props.size || props.fontSize || "48px",
        lineHeight: "167.19%",
        color: props.color || "#7C38F4",
    }),
})

const SectionTitle = props => {
    const classes = useStyles(props);
    return (
        <h3 className={classes.SectionHeader}>
            {props.children}
        </h3>
    )
}

export default SectionTitle;