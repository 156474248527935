import makeStyles from "@material-ui/core/styles/makeStyles";
import BannerBackdrop from "./BannerBackdrop/BannerBackdrop";
import bioBanner from "../../../../assets/bio/background/bio-banner.jpg"
import {Button} from "@material-ui/core";
import LearnMoreButton from "../Buttons/LearnMoreButton";
import ContactUsButton from "../Buttons/ContactUsButton";
import {useContext, useEffect, useState} from "react";
import {EnvironmentContext} from "../../../App/App";
import Modal from '@material-ui/core/Modal';
import {isBrowser, isMobile} from "react-device-detect";
import {cssSizeToPX} from "../../../../BigBoyFunctions";
import {BrowserView, MobileView} from "react-device-detect"

const useStyles = makeStyles({
    WelcomeBanner: props => ({
        height: props.innerWidth > 600 ? "100vh" : "160vh",
        width: "100vw",
        // boxSizing: "border-box",
        // overflowX: "hidden",
        margin: 0,
    }),
    topSpacer: props => ({
        height: props.innerWidth > 600 ? 225 : 75,
        // height: props.percent(10),
        width: "70%"
    }),
    bannerContent: props => ({
        // background: "black"
        boxSizing: "border-box",
        padding: "2.5%",
        display: "flex",
        flexDirection: "column",
        width: props.innerWidth < 1200 ? "100%" : "60%",
        float: "left",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        zIndex: 0
    }),
    welcomeArt: props => ({
        background: `url(${bioBanner})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        // backgroundAttachment: "fixed",
        backgroundPosition: "center top",
        // height: "90vh",
        zIndex: -5,
        // maskImage: "linear-gradient(300deg, rgba(255,255,255,1) 25%, rgba(255,255,255,0) 95%)",
        height: props.imgDims[1],
        // backgroundSize: window.innerWidth > 900 ? "120vw" : 200,
        // width: "100vw"
    }),
    header: props => ({
        fontFamily: "Montserrat, sans-serif",
        fontStyle: "normal",
        fontWeight: 200,
        fontSize: props.innerWidth > 600 ? 48 : 36,
        lineHeight: "140%",
        textAlign: "center",
        color: "#1E217C",
        marginBottom: 0,
        marginLeft: 12,
        marginRight: 12,
    }),
    subHeader: props => ({
        fontFamily: "Montserrat, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: props.innerWidth > 600 ? 48 : 36,
        lineHeight: "140%",
        textAlign: "center",
        color: "#695CC1",
        marginTop: 0,
        marginLeft: 12,
        marginRight: 12,
        marginBottom: props.percent(5),
    }),
    buttonRow: props => ({
        marginBottom: props.percent(5),
    })
});

const WelcomeBanner = props => {
    const {scaleMinWinDim, innerWidth} = useContext(EnvironmentContext);
    const [imgDims, setImgDims] = useState([0,0]);


    useEffect(() => {
        let img = new Image();

        img.onload = () => {
            setImgDims([img.width, img.height])
        };

        img.src = bioBanner
    }, []);

    const classes = useStyles({percent: scaleMinWinDim, imgDims, innerWidth});



    return (
        <section className={classes.WelcomeBanner}>
            <div className={classes.welcomeArt}/>

            <div className={classes.bannerContent}>

                <div className={classes.topSpacer}/>

                <h1 className={classes.header}>
                    Discover and Advance
                </h1>
                <h1 className={classes.subHeader}>
                    with cutting edge biological data science
                </h1>

                <div className={classes.buttonRow}>
                    <LearnMoreButton width={250} height={75} style={{margin: 40}}/>
                    <ContactUsButton children={"Get started"} width={250} height={75} style={{margin: 40}}
                                     background={"linear-gradient(92.72deg, #FF7A00 0.92%, #FFA800 88.24%)"}/>
                </div>
            </div>
        </section>
    )
};


export default WelcomeBanner;